exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-newsroom-events-js": () => import("./../../../src/pages/newsroom/events.js" /* webpackChunkName: "component---src-pages-newsroom-events-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-newsroom-news-js": () => import("./../../../src/pages/newsroom/news.js" /* webpackChunkName: "component---src-pages-newsroom-news-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blog-detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-event-detail-js": () => import("./../../../src/templates/event-detail.js" /* webpackChunkName: "component---src-templates-event-detail-js" */),
  "component---src-templates-news-detail-js": () => import("./../../../src/templates/news-detail.js" /* webpackChunkName: "component---src-templates-news-detail-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

