/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./src/css/main.scss";

export const shouldUpdateScroll = ({ prevRouterProps, routerProps, getSavedScrollPosition }) => {
  const changedPage = prevRouterProps?.location?.pathname !== routerProps?.location?.pathname;
  const hasHash = !!routerProps?.location?.hash;

  if (changedPage && !hasHash) {
    // Cambio pagina normale, gestisco io lo scroll
    const currentPosition = getSavedScrollPosition(routerProps.location);
    let scrollPosition = currentPosition || [1, 1];
    if (scrollPosition?.[0] === 0 && scrollPosition?.[1] === 0) {
      scrollPosition = [1, 1];
    }
    // console.log("Scroll to: ", scrollPosition);
    window.scrollTo(...scrollPosition);
    return false;
  } else if (changedPage && hasHash) {
    // Cambio pagina con hash, faccio gestire a Gatsby lo scroll verso l'id
    return true;
  } else if (hasHash) {
    // Cambio solo hash, lascio gestire a Gatsby lo scroll verso l'id
    return true;
  } else {
    // Stessa pagina senza, hash, solo query string, non fare nulla
    return false;
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Cambio pagina con hash non esistente in pagina
  if (
    location.pathname !== prevLocation?.pathname &&
    location.hash.slice(1) &&
    !document.querySelector(decodeURI(location.hash))
  ) {
    window.scrollTo(1, 1);
  }
};

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.GATSBY_RECAPTCHA_PUBLIC_KEY}
    language="en"
    // useRecaptchaNet="[optional_boolean_value]"
    // useEnterprise="[optional_boolean_value]"
    scriptProps={{
      async: true,
      defer: true,
    }}
  >
    {element}
  </GoogleReCaptchaProvider>
);
